import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import ProfileLayout from 'components/layouts/profile/profile.layout';

import { accountUpdateProfileRoutine } from 'state/account/account.routines';
import { accountProfileSelector } from 'state/account/account.selectors';
import { ProfileRequestErrorPayload } from 'state/account/account.services';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

import { PersonalInformation } from 'types/personal-information';

import { getPhoneNumber } from 'util/globalVariables';

import PersonalInformationForm from './intra-page-items/_personal-information.item';
import UpdateProfileModalContent from './intra-page-items/_profile-update-modal.item';

const PersonalInfo = () => {
    const { t } = useTranslation();
    const profileObject = useSelector(accountProfileSelector);
    const dispatch = useDispatch();

    const defaultValues = {
        firstName: profileObject?.patientFirstName ? profileObject?.patientFirstName : '',
        lastName: profileObject?.patientLastName ? profileObject?.patientLastName : '',
        email: profileObject?.patientEmailAddress ? profileObject?.patientEmailAddress : '',
        dateOfBirth: profileObject?.patientDob ? profileObject?.patientDob : '',
        phoneNumber:
            profileObject?.phones && profileObject?.phones[0]
                ? `1${profileObject?.phones[0].phoneAreaCode}${profileObject?.phones[0].phoneNumber}`
                : ''
    };

    const handleSuccess = useCallback(
        (submissionCallback: () => void) => {
            dispatch(
                openModal({
                    showClose: true,
                    bodyContent: <UpdateProfileModalContent area={t('modals.updateProfile.areas.profile')} />,
                    ctas: [
                        {
                            label: t('modals.updateProfile.labels.gotIt'),
                            variant: 'primary',
                            onClick: () => dispatch(closeModal({})),
                            dataGALocation: 'PersonalInfoUpdateProfile'
                        }
                    ]
                })
            );
            submissionCallback();
        },
        [dispatch, t]
    );

    const handleFailure = useCallback(
        (error: ProfileRequestErrorPayload, submissionCallback: () => void) => {
            const isDuplicateEmailError = error?.messageErrorText === 'Error updating email address on Auth0';
            const modalContent = isDuplicateEmailError ? (
                <BirdiModalContentAlt
                    subTitle={t('modals.updateProfile.failureDuplicatedEmail')}
                    note={t('modals.updateProfile.additionalInformation', {
                        phoneNumber: getPhoneNumber({ isEnd: true })
                    })}
                />
            ) : (
                <BirdiModalContentAlt subTitle={t('modals.updateProfile.failure')} />
            );

            dispatch(
                openModal({
                    showClose: false,
                    size: 'lg',
                    type: 'danger',
                    className: 'update-fail-modal',
                    headerContent: (
                        <BirdiModalHeaderDanger
                            icon="alert"
                            headerText={t(
                                `modals.updateProfile.${isDuplicateEmailError ? 'errorDuplicatedEmail' : 'error'}`
                            )}
                        />
                    ),
                    bodyContent: modalContent,
                    ctas: [
                        {
                            label: t(`modals.updateProfile.labels.${isDuplicateEmailError ? 'tryAgain' : 'gotIt'}`),
                            variant: 'primary',
                            onClick: () => dispatch(closeModal({})),
                            dataGALocation: 'PersonalInfoUpdateProfileError'
                        }
                    ]
                })
            );
            submissionCallback();
        },
        [dispatch, t]
    );

    const handleSubmit = (personalInformation: PersonalInformation, submissionCallback: () => void) => {
        const { email, phoneNumber } = personalInformation;
        const phone = parsePhoneNumberFromString(phoneNumber, 'US');
        const phones = [
            {
                ...profileObject?.phones[0],
                phoneAreaCode: phone?.nationalNumber.substr(0, 3),
                phoneNumber: phone?.nationalNumber.substr(3),
                uiPhone: phoneNumber
            }
        ];
        const updatedUser = { ...profileObject, phones, patientEmailAddress: email };
        dispatch(
            accountUpdateProfileRoutine({
                ...updatedUser,
                onSuccess: () => handleSuccess(submissionCallback),
                onFailure: (error: ProfileRequestErrorPayload) => handleFailure(error, submissionCallback)
            })
        );
    };

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.eyebrowText`)}
            title={t(`pages.profile.personalInformation.title`)}
            heading={t(`pages.profile.personalInformation.heading`)}
        >
            <PersonalInformationForm defaultValue={defaultValues} onSubmit={handleSubmit} />
        </ProfileLayout>
    );
};

export default PersonalInfo;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
